import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { fetchRequests } from "services/requestService";
import Table from "components/Table";
import { UserRequest } from "models/UserRequest";
import RowSubComponent from "components/Table/RowSubcomponent";
import StatusColumnFilter from "components/Table/StatusColumnFilter";
import { useSearchParams } from "react-router-dom";
import { useUserContext } from "components/contexts/UserContext";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { encodeToBase64 } from "@multiversx/sdk-dapp/utils";

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const accInfo = useGetAccountInfo();

  const { setSignature } = useUserContext();

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: StatusColumnFilter,
        filter: "statusFilter",
      },
      {
        Header: "First Name",
        accessor: "firstName",
        disableFilters: true,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        disableFilters: true,
      },
      {
        Header: "Phone",
        accessor: "tel",
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
      },
      // {
      //     Header: "Address",
      //     accessor: "WalletAddress",
      //    canFilter: false,
      // },

      {
        Header: "CNP",
        accessor: "cnp",
        disableFilters: true,
      },
      {
        Header: "Home Address",
        accessor: "physicalAddress",
        disableFilters: true,
      },
      {
        Header: "Confidence level",
        accessor: "confidence",
        disableFilters: true,
      },
      {
        Header: "Status Reason",
        accessor: "statusReason",
        disableFilters: true,
      },
      // {
      //     Header: "Id",
      //     accessor: "idUrls",
      // },
      // {
      //     Header: "Selfie",
      //     accessor: "selfieUrl",
      // },
      // {
      //     Header: "WarrantUrl",
      //     accessor: "WarrantUrls",
      // },
    ],
    []
  );

  React.useEffect(() => {
    const signature = searchParams.get("signature");
    console.log(signature);
    if (signature) {
      setSignature(signature);
      localStorage.setItem(
        "token",
        encodeToBase64(
          JSON.stringify({
            address: accInfo.address,
            signature: signature,
          })
        )
      );
    }
  }, []);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.

      // Or, override the default text filter to use
      // "startWith"
      statusFilter: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          console.log(filterValue);
          const rowValue = row.values[id];
          if (filterValue === undefined || filterValue?.length === 0) {
            return true;
          }
          return filterValue.includes(rowValue);
        });
      },
    }),
    []
  );

  const [data, setData] = React.useState<UserRequest[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setLoading(true);
      fetchRequests(pageIndex, pageSize)
        .then((response) => {
          let newData: UserRequest[] = response.data.map((r: any) => {
            return {
              id: r.id,
              status: r.status,
              userID: r.userID,
              firstName: r.firstName,
              lastName: r.lastName,
              cnp: r.cnp,
              physicalAddress: r.physicalAddress,
              tel: r.user.tel,
              email: r.user.email,
              confidence: r.confidence,
              statusReason: r.statusReason,
            };
          });

          setData(newData);
          setPageCount(Math.ceil(response.totalCount / pageSize));
          setLoading(false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    []
  );

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }: any) => (
      <RowSubComponent
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            filterTypes={filterTypes}
            renderRowSubComponent={renderRowSubComponent}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
