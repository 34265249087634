import { backendURL } from "config";
import axios from "axios";
import { routeNames } from "routes";
export async function fetchRequests(pageIndex: number, pageSize: number) {
    let realPage = pageIndex + 1;
    try {
        let response = await axios.get(
            backendURL +
                "/requests/?page=" +
                realPage +
                "&itemsPerPage=" +
                pageSize,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        console.log(response);
        let body = await response.data;
        return body;
    } catch (error) {
        console.error(error);
        window.location.href = routeNames.permissionDenied;
    }
}
export async function fetchRequestVerify() {
    try {
        let response = await axios.get(backendURL + "/requests/verify", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function fetchRequest(id: string) {
    try {
        let response = await axios.get(backendURL + `/requests/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error(error);
        window.location.href = routeNames.permissionDenied;
    }
    return null;
}

export async function updateFailSendToRetryRequest(id: string) {
    try {
        let response = await axios.get(
            backendURL + `/requests/updateFail/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error(error);
        //window.location.href = routeNames.permissionDenied;
    }
    return null;
}

export async function updateStatus(status: string, id: string, reason: string) {
    let response = await axios.put(
        backendURL + "/requests/status/" + id,
        {
            status: status,
            statusReason: reason,
        },
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
    );

    console.log(response);
    return response;
}
