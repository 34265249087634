import { Container, Row, Col, Card } from "react-bootstrap";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks";
const RequestView = ({ data }: any) => {
  const { network } = useGetNetworkConfig();
  return (
    <Container className="p-4">
      <Row>
        <Col>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>First Name:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.firstName}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>Last Name:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.lastName}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>Phone:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.user.tel}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>Email:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.user.email}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>Wallet Address:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.user.walletAddress}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>CNP:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.cnp}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>Address:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.physicalAddress}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h4>
                <strong>Status:</strong>
              </h4>
            </Col>
            <Col lg={8}>
              <h4>{data.status}</h4>
            </Col>
          </Row>
          {data.statusReason && (
            <Row>
              <Col lg={4}>
                <h4>
                  <strong>Status Reason:</strong>
                </h4>
              </Col>
              <Col lg={8}>
                <h4>{data.statusReason}</h4>
              </Col>
            </Row>
          )}
          {data.paymentTx && (
            <Row>
              <Col lg={4}>
                <h4>
                  <strong>Payment transaction:</strong>
                </h4>
              </Col>
              <Col lg={8}>
                <a
                  href={`${network.explorerAddress}/transactions/${data.paymentTx}`}
                  {...{
                    target: "_blank",
                  }}
                  className="tx-link"
                  title="View in Explorer"
                >
                  <h4>{data.paymentTx}</h4>
                </a>
              </Col>
            </Row>
          )}
          {data.confidence !== undefined && (
            <Row>
              <Col lg={4}>
                <h4>
                  <strong>Confidence level:</strong>
                </h4>
              </Col>
              <Col lg={8}>
                <h4>{data.confidence}</h4>
              </Col>
            </Row>
          )}
          <Row className="my-4">
            {/*    <Col lg={6}>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={data.birthCertificateUrl}
                                    height={500}
                                />
                                <Card.Body>
                                    <Card.Text>
                                        <a href={data.birthCertificateUrl}>
                                            <h4 className="text-center">
                                                <strong>
                                                    Birth Certificate
                                                </strong>
                                            </h4>
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col> */}
            <Col lg={6}>
              <Card>
                <Card.Img
                  height={500}
                  variant="top"
                  className={"request-image"}
                  src={data.selfieUrl}
                />
                <Card.Body>
                  <Card.Text>
                    <a href={data.selfieUrl}>
                      <h4 className="text-center">
                        <strong>Selfie</strong>
                      </h4>
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg={6}>
              <Card>
                <Card.Img
                  height={500}
                  variant="top"
                  className={"request-image"}
                  src={data.idUrls[0]}
                />
                <Card.Body>
                  <Card.Text>
                    <a href={data.idUrls[0]}>
                      <h4 className="text-center">
                        <strong>ID Front</strong>
                      </h4>
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <Card.Img
                  height={500}
                  variant="top"
                  className={"request-image"}
                  src={data.idUrls[1]}
                />
                <Card.Body>
                  <Card.Text>
                    <a href={data.idUrls[1]}>
                      <h4 className="text-center">
                        <strong>ID Back</strong>
                      </h4>
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RequestView;
