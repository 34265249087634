/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import BTable from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import {
  useTable,
  useRowSelect,
  useExpanded,
  usePagination,
  useFilters,
} from "react-table";
import { UserRequest, RequestStatus } from "models/UserRequest";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import RowSubComponent from "./RowSubcomponent";
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import {
  updateStatus,
  updateFailSendToRetryRequest,
} from "services/requestService";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import ConfirmationModal from "./ConfirmationModal";
import { useUserContext } from "components/contexts/UserContext";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";

const Table = ({
  columns,
  data,
  fetchData,
  loading,
  renderRowSubComponent,
  filterTypes,
  pageCount: controlledPageCount,
}: {
  columns: any;
  data: UserRequest[];
  fetchData: Function;
  loading: boolean;
  renderRowSubComponent: Function;
  filterTypes: any;
  pageCount: number;
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    allColumns,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize, selectedRowIds, hiddenColumns },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        // eslint-disable-next-line array-callback-return
        hiddenColumns: columns.map((column: any) => {
          if (column.show === false) return column.accessor || column.id;
        }),
      },
      filterTypes,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    useExpanded,
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          // Make an expander cell
          Header: () => null, // No header
          id: "expander", // It needs an ID
          Cell: ({ row }: any) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          ),
        },
        // {
        //     id: "selection",
        //     // The header can use the table's getToggleAllPageRowsSelectedProps method
        //     // to render a checkbox
        //     Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //         <div>
        //             <IndeterminateCheckbox
        //                 {...getToggleAllPageRowsSelectedProps()}
        //             />
        //         </div>
        //     ),
        //     // The cell can use the individual row's getToggleRowSelectedProps method
        //     // to the render a checkbox
        //     Cell: ({ row }: any) => (
        //         <div>
        //             <IndeterminateCheckbox
        //                 {...row.getToggleRowSelectedProps()}
        //             />
        //         </div>
        //     ),
        // },

        ...columns,
        {
          // Make an expander cell
          Header: "Actions", // No header
          id: "actions", // It needs an ID
          Cell: ({ row }: any) => {
            if (row.values.status === RequestStatus.VALIDATED) {
              return (
                <div className="action-buttons">
                  <Button
                    variant="success"
                    className="m-1"
                    onClick={() =>
                      showConfirmation(RequestStatus.APPROVED, row.values.id)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    variant="danger"
                    className="m-1"
                    onClick={() =>
                      showConfirmation(RequestStatus.DENIED, row.values.id)
                    }
                  >
                    Deny
                  </Button>
                </div>
              );
            } else if (
              row.values.status === RequestStatus.APPROVED ||
              row.values.status === RequestStatus.SENT ||
              row.values.status === RequestStatus.CLAIMED
            ) {
              return (
                <div className="action-buttons">
                  <Button
                    variant="danger"
                    className="m-1"
                    onClick={() =>
                      showConfirmation(RequestStatus.BANNED, row.values.id)
                    }
                  >
                    Ban
                  </Button>
                </div>
              );
            } else if (row.values.status === RequestStatus.FAIL_SEND) {
              return (
                <div className="action-buttons">
                  <Button
                    variant="warning"
                    className="m-1"
                    onClick={() =>
                      showConfirmation(RequestStatus.CLAIMED, row.values.id)
                    }
                  >
                    Reset Claimed
                  </Button>
                </div>
              );
            } else {
              return <></>;
            }
          },
        },
      ]);
    }
  );

  const [reload, setReload] = React.useState(false);
  const [statusToChange, setStatusToChange] = React.useState("");
  const [idToChange, setidToChange] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [showColumns, setShowColumns] = React.useState(false);

  const handleClose = () => setShow(false);

  const handleCloseColumns = () => setShowColumns(false);
  const handleShowColumns = () => setShowColumns(true);
  const login = useGetLoginInfo();
  const { signature, reloadFetch } = useUserContext();

  React.useEffect(() => {
    console.log(signature);
    if (login.loginMethod === "wallet" && !signature) {
      return;
    }
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize, reload, signature]);

  function changeStatus(reason: string) {
    setReload(true);
    setShow(false);
    if (statusToChange === RequestStatus.CLAIMED) {
      updateFailSendToRetryRequest(idToChange).then((response) => {
        setReload(false);
      });
    } else {
      updateStatus(statusToChange, idToChange, reason).then((response) => {
        setReload(false);
      });
    }
  }

  function showConfirmation(status: string, id: string) {
    setStatusToChange(status);
    setidToChange(id);
    setShow(true);
  }

  return (
    <div className="table-wrapper">
      <div className="my-4">
        <Button
          variant="primary"
          className="m-1"
          onClick={() => handleShowColumns()}
        >
          Set columns
        </Button>
        <Link
          to={routeNames.verify}
          className="btn btn-primary m-1 float-right btn-link"
          role="button"
        >
          Verify
        </Link>
      </div>

      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>
              <div>{column.canFilter ? column.render("Filter") : null}</div>
            </th>
          ))}
        </tr>
      ))}
      <BTable striped hover={true} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...rowProps}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {row.isExpanded &&
                  renderRowSubComponent({
                    row,
                    rowProps,
                    visibleColumns,
                  })}
              </React.Fragment>
            );
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>Loading...</td>
            ) : (
              <td colSpan={10000}>
                Showing page {pageIndex + 1} of {controlledPageCount}
              </td>
            )}
          </tr>
        </tbody>
      </BTable>
      <div className="table-pagination">
        <Button
          variant="primary"
          size="sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </Button>{" "}
        <Button
          variant="primary"
          size="sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </Button>{" "}
        <Button
          variant="primary"
          size="sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </Button>{" "}
        <Button
          variant="primary"
          size="sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ConfirmationModal
        show={show}
        statusToChange={statusToChange}
        handleClose={handleClose}
        changeStatus={changeStatus}
      ></ConfirmationModal>
      <Modal size="sm" show={showColumns} onHide={handleCloseColumns}>
        <Modal.Header>
          <Modal.Title>Select what columns to show</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{" "}
              Toggle All
            </div>
            {allColumns.map((column) => (
              <div key={column.id}>
                <label>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                  {column.id}
                </label>
              </div>
            ))}
            <br />
          </div>
        </Modal.Body>
      </Modal>
      {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            selectedRowIds: selectedRowIds,
                            "selectedFlatRows[].original": selectedFlatRows.map(
                                (d) => d.original
                            ),
                        },
                        null,
                        2
                    )}
                </code>
            </pre>
            <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
            <pre>
                <code>
                    {JSON.stringify(
                        {
                            selectedRowIds: selectedRowIds,
                            "selectedFlatRows[].original": selectedFlatRows.map(
                                (d: any) => d.original
                            ),
                        },
                        null,
                        2
                    )}
                </code>
            </pre>
            <pre>{JSON.stringify(hiddenColumns, null, 2)}</pre> */}
    </div>
  );
};

export default Table;
