import React, { useEffect } from "react";
import { useGetLoginInfo, useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { encodeToBase64 } from "@multiversx/sdk-dapp/utils";
import { AuthenticatedRoutesWrapper } from "@multiversx/sdk-dapp/wrappers";
import { useLocation } from "react-router-dom";
import routes, { routeNames } from "routes";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useUserContext } from "components/contexts/UserContext";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search, pathname } = useLocation();
  const accInfo = useGetAccountInfo();
  const login = useGetLoginInfo();
  const { signature } = useUserContext();
  // Add wallet address to every request

  useEffect(() => {
    let signatureToSend = "";
    if (login.tokenLogin?.signature) {
      signatureToSend = login.tokenLogin?.signature;
    } else {
      signatureToSend = signature;
    }

    if (signatureToSend) {
      localStorage.setItem(
        "token",
        encodeToBase64(
          JSON.stringify({
            address: accInfo.address,
            signature: signatureToSend,
          })
        )
      );
    }
  }, [accInfo, signature, login]);

  console.log(pathname);
  return (
    <div className="bg-light d-flex flex-column flex-fill wrapper">
      {pathname !== routeNames.unlock && <Navbar />}
      <main className="d-flex flex-column flex-grow-1">
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      {pathname !== routeNames.unlock && <Footer />}
    </div>
  );
};

export default Layout;
