import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { fetchRequestVerify } from "services/requestService";
import { Button } from "react-bootstrap";
import { RequestStatus } from "models/UserRequest";
import { updateStatus } from "services/requestService";
import ConfirmationModal from "components/Table/ConfirmationModal";
import RequestView from "components/Request";

const Verify = () => {
    const [data, setData] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [statusToChange, setStatusToChange] = React.useState("");
    const [reload, setReload] = React.useState(false);
    const [reason, setReason] = React.useState("");

    React.useEffect(() => {
        setLoading(true);
        fetchRequestVerify().then((response) => {
            if (response === null) {
                setData(null);
            } else {
                setData(response.data);
            }
            setLoading(false);
        });
    }, [reload]);

    const handleClose = () => setShow(false);

    function showConfirmation(status: string) {
        setStatusToChange(status);

        setShow(true);
    }

    function changeStatus(reason: string) {
        setReload(false);
        updateStatus(statusToChange, data.id, reason).then((response) => {
            setReload(true);
            setShow(false);
        });
    }

    return (
        <Container className="p-4">
            <Row>
                <Col>
                    {loading ? (
                        <div className="text-center">loading...</div>
                    ) : data == null ? (
                        <div className="text-center">no records to verify</div>
                    ) : (
                        <div>
                            <RequestView data={data} />
                            <Row>
                                <Col>
                                    <div className="action-buttons">
                                        <Button
                                            variant="success"
                                            className="m-1"
                                            onClick={() =>
                                                showConfirmation(
                                                    RequestStatus.APPROVED
                                                )
                                            }
                                        >
                                            Accept
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="m-1"
                                            onClick={() =>
                                                showConfirmation(
                                                    RequestStatus.DENIED
                                                )
                                            }
                                        >
                                            Deny
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>

            <ConfirmationModal
                show={show}
                statusToChange={statusToChange}
                handleClose={handleClose}
                changeStatus={changeStatus}
                reason={reason}
                setReason={setReason}
            ></ConfirmationModal>
        </Container>
    );
};

export default Verify;
