import React from "react";

import { Button, Modal, Form } from "react-bootstrap";
import { RequestStatus } from "models/UserRequest";

const ConfirmationModal = ({
    show,
    statusToChange,
    handleClose,
    changeStatus,
}: any) => {
    const [reason, setReason] = React.useState("");
    const [error, setError] = React.useState(false);

    const confirm = () => {
        if (
            (statusToChange === RequestStatus.DENIED ||
                statusToChange === RequestStatus.BANNED) &&
            reason === ""
        ) {
            setError(true);
        } else {
            changeStatus(reason);
            setReason("");
            setError(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to set the status to {statusToChange}?
                {statusToChange === RequestStatus.DENIED ||
                statusToChange === RequestStatus.BANNED ? (
                    <>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Reason for deny*</Form.Label>
                            <Form.Control
                                id=""
                                as="textarea"
                                rows={3}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                isInvalid={error}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a reason for the {statusToChange}{" "}
                                request.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                ) : (
                    ""
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={confirm}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
