import * as React from "react";
import { Link } from "react-router-dom";
import { dAppName } from "config";
import { routeNames } from "routes";
import { Container, Row, Col, Card } from "react-bootstrap";

const Home = () => {
  return (
    <Container className="d-flex flex-fill align-items-center">
      <Row className=" w-100">
        <Col md={8} lg={5} className="mx-auto">
          <Card className="shadow-sm rounded p-4 border-0">
            <Card.Body className="text-center">
              <h2 className="mb-3" data-testid="title">
                {dAppName}
              </h2>
              <p className="mb-3">Intră folosind portofelul MultiversX</p>

              <Link
                to={routeNames.unlock}
                className="btn btn-primary mt-3 text-white"
                data-testid="loginBtn"
              >
                Login
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
