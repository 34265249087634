export const decimals = 4;
export const denomination = 18;
export const gasPerDataByte = 1500;
export const timeout = 10000; // 10 sec
export const dAppName = "eLeu";

export const environment = "mainnet";
export const apiUrl = "https://api.elrond.com";

//export const backendURL = "http://localhost:8080";
export const backendURL = "https://vangov.eleu.tech";
