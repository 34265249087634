import React from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";
import {
  Navbar as BsNavbar,
  NavItem,
  Nav,
  Modal,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { dAppName } from "config";
import { routeNames } from "routes";
import ElrondLogo from "./../../../assets/img/logo.png";

const Navbar = () => {
  const { address } = useGetAccountInfo();
  const [show, setShow] = React.useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    logout(`${window.location.origin}/unlock`);
  };

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar className="bg-white border-bottom px-4 py-3">
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Log Out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Log Out
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-fluid">
        <Link
          className="d-flex align-items-center navbar-brand mr-0"
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <img
            src={ElrondLogo}
            alt="eLeu logo"
            style={{ height: 64, width: 64 }}
          />
          <span className="dapp-name text-muted">{dAppName}</span>
        </Link>

        <Nav className="ml-auto">
          {isLoggedIn && (
            <NavItem>
              <Button variant="secondary" onClick={() => setShow(true)}>
                Log Out
              </Button>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
