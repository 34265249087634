import React from "react";
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
} from "@multiversx/sdk-dapp/UI";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { routeNames } from "routes";
import { Card } from "react-bootstrap";
export const UnlockRoute: () => JSX.Element = () => {
  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.dashboard;
    }
  }, [isLoggedIn]);

  // Typescript definitions are messed up for WalletConnectLoginButton.
  const buttonProps = {
    token: "asd",
    nativeAuth: true,
  } as any;

  //token "asd" trebuie inlocuit cu unul generat pe server
  return (
    <div className="unlock d-flex flex-fill align-items-center">
      <div className="m-auto" data-testid="unlockPage">
        <Card className="my-4 unlock-card">
          <Card.Body className="mx-4">
            <h4 className="mb-4">Connect to a wallet</h4>
            <div className="login-buttons-wrapper">
              <ExtensionLoginButton
                className="login-buttons"
                {...buttonProps}
                loginButtonText="Extensie browser xPortal"
              />
            </div>
            <div className="login-buttons-wrapper">
              <WalletConnectLoginButton
                {...buttonProps}
                isWalletConnectV2
                token="asd"
                loginButtonText="Aplicație xPortal"
              />
            </div>
            <div className="login-buttons-wrapper">
              <LedgerLoginButton
                className="login-buttons"
                callbackRoute={routeNames.dashboard}
                nativeAuth={true}
                token={"asd"}
                loginButtonText="Ledger"
              />
            </div>

            <div className="login-footer text-center">
              <p>Nu ai un portofel?</p>
              <a
                href="https://wallet.multiversx.com/create"
                target="_blank"
                rel="noreferrer"
              >
                E simplu să îți creezi unul!
              </a>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default UnlockRoute;
