import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import UnlockPage from "./pages/UnlockPage";
import Verify from "pages/Verify";
import PermissionDenied from "pages/PermissionDenied";

export const routeNames = {
    home: "/",
    dashboard: "/dashboard",
    transaction: "/transaction",
    unlock: "/unlock",
    ledger: "/ledger",
    walletconnect: "/walletconnect",
    verify: "/verify",
    permissionDenied: "/permissionDenied",
};

const routes: Array<any> = [
    {
        path: routeNames.home,
        title: "Home",
        component: Home,
    },
    {
        path: routeNames.dashboard,
        title: "Dashboard",
        component: Dashboard,
        authenticatedRoute: true,
    },
    {
        path: routeNames.unlock,
        title: "Conectare",
        component: UnlockPage,
    },
    {
        path: routeNames.permissionDenied,
        title: "Permission Denied",
        component: PermissionDenied,
    },
    {
        path: routeNames.verify,
        title: "Verificare",
        component: Verify,
        authenticatedRoute: true,
    },
];

const mappedRoutes = routes.map((route) => {
    const title = route.title ? `${route.title} • ${dAppName}` : ` ${dAppName}`;

    const requiresAuth = Boolean(route.authenticatedRoute);
    const wrappedComponent = withPageTitle(title, route.component);

    return {
        path: route.path,
        component: wrappedComponent,
        authenticatedRoute: requiresAuth,
    };
});

export default mappedRoutes;
