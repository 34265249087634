/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "react-bootstrap";
import React from "react";

export default function StatusColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}: any) {
    const [toggle, setToggle] = React.useState(false);

    React.useEffect(() => {
        if (toggle) {
            setFilter(["validated"]);
        } else {
            setFilter(undefined);
        }
    }, [toggle]);

    return (
        <div className={"m-4"}>
            <Button
                variant="outline-primary"
                active={toggle}
                onClick={() => {
                    setToggle(!toggle);
                }}
            >
                Only Validated
            </Button>
        </div>
    );
}
