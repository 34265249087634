import React from "react";
import { fetchRequest } from "services/requestService";
import RequestView from "components/Request";

const RowSubComponent = ({ row, rowProps, visibleColumns }: any) => {
    const [loadingSub, setLoadingSub] = React.useState(false);
    const [data, setData] = React.useState<any>(null);

    React.useEffect(() => {
        setLoadingSub(true);
        fetchRequest(row.values.id).then((response) => {
            if (response === null) {
                setData(null);
            } else {
                setData(response.data);
            }
            setLoadingSub(false);
        });
    }, [row.values.id]);

    if (loadingSub) {
        return (
            <tr>
                <td />
                <td colSpan={visibleColumns.length - 1}>Loading...</td>
            </tr>
        );
    }

    return (
        <tr>
            <td />
            <td colSpan={visibleColumns.length - 1}>
                {data === null ? (
                    "No data"
                ) : (
                    <RequestView data={data}></RequestView>
                )}
            </td>
        </tr>
    );
};

export default RowSubComponent;
